<template>
    <b-container class="m-0 w-100 pt-2" fluid>
    <portal to="createButtonMotive">
      <b-row class="justify-content-end">
        <b-button class="mr-2" variant="success" @click="OpenModal">CREATE</b-button>
      </b-row>
    </portal>
    
    <b-card>
       
      <b-row>
        <b-col >
            <motives-table :key="tableKey" @editMotive="editMotive"/>
        </b-col>
      </b-row>
    </b-card>

    <modal-motives-form v-if="showModal" 
    :stateModal="stateModal"
    :motiveData="motiveData"
    @hide="closeModal"
    @reload="reload"
    />
  </b-container>
</template>
<script>
import MotivesTable from "@/views/ce-digital/sub-modules/settings/views/motives/components/MotivesTable.vue";
import ModalMotivesForm from '@/views/ce-digital/sub-modules/settings/views/motives/components/ModalMotivesForm.vue';
export default {
  components: { MotivesTable,ModalMotivesForm },
  data() {
    return {
        showModal : false,
        tableKey : "KEY",
        stateModal : 1,
        motiveData :{},
    };
  },
  methods:{
    OpenModal(){
        this.showModal=true;
    },
    closeModal(){
        this.showModal=false;
        this.stateModal = 1 ;
        this.motiveData = {};
    },
    reload(){
      this.closeModal();
      this.tableKey = this.tableKey+Math.random();
    },
    editMotive(item){
      this.stateModal = 2 ;
      this.motiveData = item;
      this.showModal=true;  

    }
  }
};
</script>
<style>
</style>
