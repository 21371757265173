<template>
  <div>
    <b-nav card-header pills class="m-0">
      <b-nav-item
        :to="{ name: 'settings-motives-application' }"
        exact
        exact-active-class="active"
        :active="routeName == 'settings-motives-list'"
        :link-classes="['px-3', bgTabsNavs]"
      >
        APPLICATION MOTIVES
      </b-nav-item>
    </b-nav>
    <b-card
      no-body
      class="border-top-primary border-3 border-table-radius px-0"
    >
      <router-view :key="$route.name" ref="routerView" />
    </b-card>
  </div>
</template>
<script>
export default {
  computed: {
    routeName() {
      return this.$route.matched[2].name;
    },
  },
};
</script>