<template>
  <div>
    <div :class="G_DATA_NOTE.id ? 'd-flex justify-content-between' : ''">
      <h4>
        {{ G_TYPE_NOTE }}
      </h4>

      <b-button v-if="G_DATA_NOTE.id" variant="primary" @click="newNote(false)">
        <feather-icon icon="FileTextIcon"> </feather-icon>
        NEW NOTE</b-button
      >
    </div>

    <ValidationObserver ref="formNote">
      <label for="title" size="lg" class="pt-1">Title</label>
      <ValidationProvider v-slot="{ errors }" name="title" rules="required">
        <b-form-input
          label-for="title"
          type="text"
          v-model="G_DATA_NOTE.title"
          :disabled="G_SHOW_NOTE"
          :class="
            isDarkSkin
              ? { 'border-danger': errors[0] }
              : { 'border border-danger': errors[0] }
          "
        />
      </ValidationProvider>
      <b-form-group label="Note" label-for="note" class="pt-1">
        <ValidationProvider v-slot="{ errors }" name="note" rules="required">
          <b-form-textarea
            id="note"
            v-model="G_DATA_NOTE.note"
            placeholder="Enter something..."
            rows="4"
            max-rows="10"
            :disabled="G_SHOW_NOTE"
            style="height: 160px; max-height: 200px"
            :class="
              isDarkSkin
                ? { 'border-danger': errors[0] }
                : { 'border border-danger': errors[0] }
            "
          >
          </b-form-textarea>
        </ValidationProvider>
      </b-form-group>
    </ValidationObserver>
    <b-row class="d-flex justify-content-end pt-1 pr-1" v-if="!G_SHOW_NOTE">
      <b-button v-if="G_DATA_NOTE.id" variant="warning" @click="submit">
        <feather-icon icon="Edit3Icon"> </feather-icon>
        UPDATE</b-button
      >
      <b-button v-else variant="primary" @click="submit">
        <feather-icon icon="SaveIcon"> </feather-icon>
        SAVE</b-button
      >
    </b-row>
  </div>
</template>
<script>
import NoteService from "@/views/ce-digital/sub-modules/settings/views/notes/service/notes.service";
import { mapGetters, mapActions } from "vuex";
export default {
  components: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
      G_DATA_NOTE: "CeDigitalNotes/G_DATA_NOTE",
      G_SHOW_NOTE: "CeDigitalNotes/G_SHOW_NOTE",
      G_TYPE_NOTE: "CeDigitalNotes/G_TYPE_NOTE",
    }),
  },
  created() {
    this.A_TYPE_NOTE("NEW NOTE");
  },
  methods: {
    ...mapActions({
      A_UPDATED_NOTE_TABLE: "CeDigitalNotes/A_UPDATED_NOTE_TABLE",
      A_DATA_NOTE: "CeDigitalNotes/A_DATA_NOTE",
      A_SHOW_NOTE: "CeDigitalNotes/A_SHOW_NOTE",
      A_TYPE_NOTE: "CeDigitalNotes/A_TYPE_NOTE",
    }),
    async newNote(show) {
      await this.A_DATA_NOTE({
        title: null,
        note: null,
      });
      this.$refs.formNote.reset();
      this.A_SHOW_NOTE(show);
      this.A_TYPE_NOTE("NEW NOTE");
    },
    async submit() {
      let isValidated = await this.$refs.formNote.validate();
      if (isValidated) {
        const { data } = await NoteService.getNote({
          title: this.G_DATA_NOTE.title,
        });

        let result = await this.showConfirmSwal();
        if (result.isConfirmed) {
          const paramsSave = {
            note: this.G_DATA_NOTE.note,
            title: this.G_DATA_NOTE.title,
            user: this.currentUser.user_id,
          };
          const paramsUpdate = {
            id: this.G_DATA_NOTE.id,
            note: this.G_DATA_NOTE.note,
            title: this.G_DATA_NOTE.title,
            user: this.currentUser.user_id,
          };
          if (this.G_DATA_NOTE.id) {
            try {
              const resultUpdate = await NoteService.updateNote(paramsUpdate);
              this.addPreloader();
              if (resultUpdate.status == 200) {
                this.notification("updated");
              }
            } catch (error) {
              throw error;
            }
          } else {
            if (data[0].cc > 0) {
              this.showWarningSwal("IMPORTANT!", "This note already exists");
            } else {
              try {
                this.addPreloader();
                const resultSave = await NoteService.setNote(paramsSave);
                if (resultSave.status == 200) {
                  this.notification("created");
                }
              } catch (error) {
                throw error;
              }
            }
          }
        }
      }
    },
    async notification(text) {
      await this.A_DATA_NOTE({
        title: null,
        note: null,
      });
      this.$refs.formNote.reset();
      this.updateTableNote();
      this.showToast(
        "success",
        "top-right",
        "Success!",
        "CheckIcon",
        `Note ${text} successfully`
      );
      this.removePreloader();
    },
    updateTableNote() {
      this.A_UPDATED_NOTE_TABLE(true);
      setTimeout(() => {
        this.A_UPDATED_NOTE_TABLE(false);
      }, 1000);
    },
  },
};
</script>