import { amgApi } from "@/service/axios";
class NotesService {
    async setNote(params) {
        try {
            const data = await amgApi.post("credit-experts-digital/set-note", params);
            return data;
        } catch (error) {
            console.log("Error setNote");
        }
    }
    async getNote(params) {
        try {
            const data = await amgApi.post("credit-experts-digital/get-note", params);
            return data;
        } catch (error) {
            console.log("Error getNote");
        }
    }
    async getNotes(params) {
        try {
            const data = await amgApi.post("credit-experts-digital/get-notes", params);
            return data;
        } catch (error) {
            console.log("Error getNotes");
        }
    }
    async updateNote(params) {
        try {
            const data = await amgApi.post("credit-experts-digital/update-note", params);
            return data;
        } catch (error) {
            console.log("Error updateNote");
        }
    }
    async deleteNote(params) {
        try {
            const data = await amgApi.post("credit-experts-digital/delete-note", params);
            return data;
        } catch (error) {
            console.log("Error deleteNote");
        }
    }
}
export default new NotesService();