<template>
  <div>
    <b-card no-body class="mb-1 border-0 p-0">
      <slot name="recovery-list" />
      <div>
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            :md="changeColumnsByClient == true ? 8 : 6"
            class="d-flex align-items-center justify-content-start"
          >
            <label>Show</label>
            <v-select
              v-model="paginate.perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="[5, 10, 25, 50]"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
              @input="$emit('reload', false)"
            />
            <label class="mr-2">entries</label>
            <feather-icon
              class="cursor-pointer"
              icon="RefreshCcwIcon"
              size="20"
              @click="resetFilter"
            />
            <slot name="buttons" />
          </b-col>
          <!-- Search -->
          <b-col cols="12" :md="changeColumnsByClient == true ? 4 : 6">
            <div class="d-flex align-items-center justify-content-end">
              <slot name="buttons-filter" />
              <b-input-group v-if="!noVisiblePrincipalFilter">
                <b-form-input
                  v-if="filterPrincipal.type === 'input'"
                  v-model="filterPrincipal.model"
                  :type="filterPrincipal.inputType"
                  :placeholder="filterPrincipal.placeholder"
                  :class="filterPrincipal.class"
                  @keyup.enter="$emit('reload')"
                />
                <b-input-group-append>
                  <b-button variant="primary" @click="$emit('reload')">
                    <feather-icon icon="SearchIcon" />
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </div>
          </b-col>
        </b-row>
      </div>
      <div class="table-responsive">
        <slot name="table" />
      </div>
      <div class="mx-2 mb-2 mt-2">
        <b-row>
          <b-col
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-start
            "
          >
            <span class="text-muted">
              Showing {{ startPage }} to {{ toPage }} of {{ totalRows }} entries
            </span>
          </b-col>
          <!-- Pagination -->
          <slot name="footer" />
          <b-col
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-end
            "
          >
            <b-pagination
              v-model="paginate.currentPage"
              :total-rows="totalRows"
              :per-page="paginate.perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
              @input="$emit('onChangeCurrentPage', $event)"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import vSelect from "vue-select";
import { mapGetters, mapState } from "vuex";

export default {
  components: {
    vSelect,
  },
  props: {
    changeColumnsByClient: { required: false, type: Boolean },
    totalRows: { required: false, type: Number },
    paginate: { required: true, type: Object },
    startPage: { required: false, type: Number },
    toPage: { required: false, type: Number },
    filterPrincipal: { required: true, type: Object },
    noVisiblePrincipalFilter: { required: false, default: false },
  },
  computed: {
    ...mapGetters({
      skin: "appConfig/skin",
    }),
    ...mapState({
      perPage: (state) => state.appConfig.perPage,
    }),
  },
  created() {
    this.filterPrincipal.model = "";
  },
  mounted() {
    this.paginate.perPage = this.perPage;
    this.$emit("reload");
  },
  watch: {
    perPage(newVal) {
      this.paginate.perPage = newVal;
      this.$emit("reload");
    },
  },
  methods: {
    resetFilter() {
      this.filterPrincipal.model = "";
      this.$emit("reload");
    },
    sideBarSearch() {
      this.filterPrincipal.model = "";
      this.$emit("reload");
    },
  },
};
</script>

<style lang="scss" scoped>
.flexible {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-around;
}
.per-page-selector {
  width: 80px;
}
td.div {
  width: 100% !important;
}
.hover-close {
  transition: 300ms;
}
.hover-close:hover {
  background-color: #ff3b19 !important;
  color: white !important;
}
@media (max-width: 960px) {
  .column-table {
    display: flex;
    flex-direction: column;
  }
}
</style>
