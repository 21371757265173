<template>
  <b-container class="m-0 w-100 pt-2" fluid>
    <b-card>
      <b-row>
        <b-col cols="6">
          <notes-tables />
        </b-col>
        <b-col cols="6">
          <form-note />
        </b-col>
      </b-row>
    </b-card>
  </b-container>
</template>
<script>
import NotesTables from "./NoteTable.vue";
import FormNote from "./FormNote.vue";
export default {
  components: { NotesTables, FormNote },
  data() {
    return {};
  },
};
</script>
<style>
</style>
