<template>
  <div>
    <h4 class="pb-1">NOTE LIST</h4>
    <note-filter
      :filter-principal="filterPrincipal"
      :filter="[]"
      :total-rows="totalRows"
      :paginate="paginate"
      :start-page="startPage"
      :to-page="toPage"
      @reload="$refs['refNotes'].refresh()"
    >
      <b-table
        slot="table"
        ref="refNotes"
        small
        :items="myProvider"
        :fields="fields"
        :current-page="paginate.currentPage"
        :per-page="paginate.perPage"
        primary-key="id"
        responsive="sm"
        show-empty
        sticky-header="70vh"
        class="position-relative font-small-3 pt-1"
      >
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle mr-1" />
            <strong>Loading ...</strong>
          </div>
        </template>
        <!-- Column: Created By -->
        <template #cell(created_by)="data">
          <div>
            <p class="m-0">{{ data.item.created_by }}</p>
            <p class="m-0">{{ data.item.created_at | myGlobalWithHour }}</p>
          </div>
        </template>
        <!-- Column: Title -->
        <template #cell(title)="data">
          <div>
            <p class="m-0" style="width: 150px; overflow-wrap: break-word">
              {{ data.item.title }}
            </p>
          </div>
        </template>
        <!-- Column: UPDATED BY-->
        <template #cell(updated_by)="data">
          <div v-if="data.item.updated_by">
            <p class="m-0">{{ data.item.updated_by }}</p>
            <p class="m-0">{{ data.item.updated_at | myGlobalWithHour }}</p>
          </div>
          <p v-else class="m-0">-</p>
        </template>
        <!-- Column: ACTIONS-->
        <template #cell(actions)="data">
          <div class="d-flex justify-content-center">
            <b-button variant="outline" @click="editNote(data.item, false)">
              <feather-icon
                icon="Edit3Icon"
                v-b-tooltip.hover.top="'Edit'"
                class="text-warning"
                size="18"
            /></b-button>
            <b-button variant="outline" @click="showNote(data.item, true)">
              <feather-icon
                icon="EyeIcon"
                class="text-success"
                size="18"
                v-b-tooltip.hover.top="'Show'"
            /></b-button>
            <b-button variant="outline" @click="deleteNote(data.item, false)">
              <feather-icon
                icon="XIcon"
                class="text-danger"
                size="18"
                v-b-tooltip.hover.top="'Delete'"
            /></b-button>
          </div>
        </template>
      </b-table>
    </note-filter>
  </div>
</template>

<script>
import NotesService from "@/views/ce-digital/sub-modules/settings/views/notes/service/notes.service";
import { mapGetters, mapActions } from "vuex";
import NoteFilter from "./ NoteFilter.vue";
export default {
  components: {
    NoteFilter,
  },
  props: {
    trackingData: {
      type: Object,
    },
    day: {
      type: String,
    },
  },
  data() {
    return {
      filterPrincipal: {
        type: "input",
        inputType: "text",
        placeholder: "Search...",
        model: null,
      },
      paginate: {
        currentPage: 1,
        perPage: 10,
      },
      startPage: 0,
      toPage: 0,
      totalRows: 0,
      fields: [
        { key: "title", thClass: "text-center", tdClass: "text-center" },
        {
          label: "created by",
          key: "created_by",
        },
        {
          label: "updated by",
          key: "updated_by",
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          key: "actions",
          thClass: "text-center",
          tdClass: "text-center",
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      G_UPDATED_NOTE_TABLE: "CeDigitalNotes/G_UPDATED_NOTE_TABLE",
    }),
  },

  methods: {
    ...mapActions({
      A_DATA_NOTE: "CeDigitalNotes/A_DATA_NOTE",
      A_SHOW_NOTE: "CeDigitalNotes/A_SHOW_NOTE",
      A_TYPE_NOTE: "CeDigitalNotes/A_TYPE_NOTE",
      A_UPDATED_NOTE_TABLE: "CeDigitalNotes/A_UPDATED_NOTE_TABLE",
    }),
    async myProvider() {
      try {
        const params = {
          name_text: this.filterPrincipal.model,
          perpage: this.paginate.perPage,
          page: this.paginate.currentPage,
        };
        const result = await NotesService.getNotes(params);
        if (result.status == 200) {
          this.startPage = result.data.from;
          this.toPage = result.data.to;
          this.totalRows = result.data.total;
          this.paginate.currentPage = result.data.current_page;
          this.paginate.perPage = result.data.per_page;
          return result.data.data;
        }
      } catch (error) {
        this.showToast(
          "danger",
          "top-right",
          "Error",
          "XIcon",
          "Something went wrong with tracking!"
        );
        throw error;
      }
    },
    async editNote(data, show) {
      this.A_DATA_NOTE(data);
      this.A_SHOW_NOTE(show);
      this.A_TYPE_NOTE("EDIT NOTE");
    },
    async showNote(data, show) {
      this.A_DATA_NOTE(data);
      this.A_SHOW_NOTE(show);
      this.A_TYPE_NOTE("SHOW NOTE");
    },
    async deleteNote(data) {
      try {
        let result = await this.showConfirmSwal();
        if (result.isConfirmed) {
          let response = await NotesService.deleteNote({ id: data.id });
          if (response.status == 200) {
            await this.A_DATA_NOTE({
              title: null,
              note: null,
            });
            this.A_SHOW_NOTE(false);
            this.A_TYPE_NOTE("NEW NOTE");
            this.$refs.refNotes.refresh();
            this.showToast(
              "success",
              "top-right",
              "Success!",
              "CheckIcon",
              "Note deleted successfully"
            );
          }
        }
      } catch (error) {
        throw error;
      }
    },
  },
  watch: {
    G_UPDATED_NOTE_TABLE(newVal) {
      if (newVal) {
        this.$refs.refNotes.refresh();
      }
    },
  },
};
</script>
