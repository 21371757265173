var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{class:_vm.G_DATA_NOTE.id ? 'd-flex justify-content-between' : ''},[_c('h4',[_vm._v(" "+_vm._s(_vm.G_TYPE_NOTE)+" ")]),(_vm.G_DATA_NOTE.id)?_c('b-button',{attrs:{"variant":"primary"},on:{"click":function($event){return _vm.newNote(false)}}},[_c('feather-icon',{attrs:{"icon":"FileTextIcon"}}),_vm._v(" NEW NOTE")],1):_vm._e()],1),_c('ValidationObserver',{ref:"formNote"},[_c('label',{staticClass:"pt-1",attrs:{"for":"title","size":"lg"}},[_vm._v("Title")]),_c('ValidationProvider',{attrs:{"name":"title","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{class:_vm.isDarkSkin
            ? { 'border-danger': errors[0] }
            : { 'border border-danger': errors[0] },attrs:{"label-for":"title","type":"text","disabled":_vm.G_SHOW_NOTE},model:{value:(_vm.G_DATA_NOTE.title),callback:function ($$v) {_vm.$set(_vm.G_DATA_NOTE, "title", $$v)},expression:"G_DATA_NOTE.title"}})]}}])}),_c('b-form-group',{staticClass:"pt-1",attrs:{"label":"Note","label-for":"note"}},[_c('ValidationProvider',{attrs:{"name":"note","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('b-form-textarea',{class:_vm.isDarkSkin
              ? { 'border-danger': errors[0] }
              : { 'border border-danger': errors[0] },staticStyle:{"height":"160px","max-height":"200px"},attrs:{"id":"note","placeholder":"Enter something...","rows":"4","max-rows":"10","disabled":_vm.G_SHOW_NOTE},model:{value:(_vm.G_DATA_NOTE.note),callback:function ($$v) {_vm.$set(_vm.G_DATA_NOTE, "note", $$v)},expression:"G_DATA_NOTE.note"}})]}}])})],1)],1),(!_vm.G_SHOW_NOTE)?_c('b-row',{staticClass:"d-flex justify-content-end pt-1 pr-1"},[(_vm.G_DATA_NOTE.id)?_c('b-button',{attrs:{"variant":"warning"},on:{"click":_vm.submit}},[_c('feather-icon',{attrs:{"icon":"Edit3Icon"}}),_vm._v(" UPDATE")],1):_c('b-button',{attrs:{"variant":"primary"},on:{"click":_vm.submit}},[_c('feather-icon',{attrs:{"icon":"SaveIcon"}}),_vm._v(" SAVE")],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }