<template>
  <div>
    <motives-filter
      :filter-principal="filterPrincipal"
      :filter="[]"
      :total-rows="totalRows"
      :paginate="paginate"
      :start-page="startPage"
      :to-page="toPage"
      @reload="$refs['refMotives'].refresh()"
    >
      <b-table
        slot="table"
        ref="refMotives"
        small
        :items="myProvider"
        :fields="fields"
        :current-page="paginate.currentPage"
        :per-page="paginate.perPage"
        primary-key="id"
        responsive="sm"
        show-empty
        sticky-header="70vh"
        class="position-relative font-small-3 pt-1"
      >
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle mr-1" />
            <strong>Loading ...</strong>
          </div>
        </template>
        <!-- Column: Created By -->
        <template #cell(created_by)="data">
          <div>
            <p class="m-0">{{ data.item.created_by_name }}</p>
            <p class="m-0">{{ data.item.created_at | myGlobalWithHour }}</p>
          </div>
        </template>
        <!-- Column: Title -->
        <template #cell(title)="data">
          <div>
              <change-sms :SmsData="data.item.title" />
          </div>
        </template>
        <!-- Column: UPDATED BY-->
        <template #cell(updated_by)="data">
          <div v-if="data.item.updated_at">
            <p class="m-0">{{ data.item.updated_by_name }}</p>
            <p class="m-0">{{ data.item.updated_at | myGlobalWithHour }}</p>
          </div>
          <p v-else class="m-0">-</p>
        </template>
        <!-- Column: ACTIONS-->
        <template #cell(actions)="data">
          <div class="d-flex justify-content-center">
            <b-button variant="outline" @click="editMotive(data.item)">
              <feather-icon
                icon="Edit3Icon"
                v-b-tooltip.hover.top="'Edit'"
                class="text-warning"
                size="18"
            /></b-button>
            <b-button variant="outline" @click="deleteMotive(data.item.id)">
              <feather-icon
                icon="XIcon"
                class="text-danger"
                size="18"
                v-b-tooltip.hover.top="'Delete'"
            /></b-button>
          </div>
        </template>
      </b-table>
    </motives-filter>
  </div>
</template>

<script>
import MotivesService from "@/views/ce-digital/sub-modules/settings/views/motives/service/motives.service.js";
import { mapGetters, mapActions } from "vuex";
import MotivesFilter from "@/views/ce-digital/sub-modules/settings/views/motives/components/MotivesFilter.vue";
import ChangeSms from "@/views/commons/components/clients/components/clientsSms/ChangeSms";
export default {
  components: {
    MotivesFilter,
    ChangeSms
  },
  props: {
    trackingData: {
      type: Object,
    },
    day: {
      type: String,
    },
  },
  data() {
    return {
      filterPrincipal: {
        type: "input",
        inputType: "text",
        placeholder: "Search...",
        model: null,
      },
      paginate: {
        currentPage: 1,
        perPage: 10,
      },
      startPage: 0,
      toPage: 0,
      totalRows: 0,
      fields: [
        {
          label: "Motive",
          key: "title",
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          label: "created by",
          key: "created_by",
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          label: "updated by",
          key: "updated_by",
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          key: "actions",
          thClass: "text-center",
          tdClass: "text-center",
        },
      ],
    };
  },

  methods: {
    async myProvider() {
      try {
        const params = {
          name_text: this.filterPrincipal.model,
          perpage: this.paginate.perPage,
          page: this.paginate.currentPage,
        };
        const result = await MotivesService.getMotives(params);
        if (result.status == 200) {
          this.startPage = result.data.from;
          this.toPage = result.data.to;
          this.totalRows = result.data.total;
          this.paginate.currentPage = result.data.current_page;
          this.paginate.perPage = result.data.per_page;
          return result.data.data;
        }
      } catch (error) {
        this.showToast(
          "danger",
          "top-right",
          "Error",
          "XIcon",
          "Something went wrong with tracking!"
        );
        throw error;
      }
    },
    editMotive(motive) {
      this.$emit("editMotive", motive);
    },
    async deleteMotive(item) {
      let result = await this.showConfirmSwal();
      if (result.isConfirmed) {
        try {
          const params = {
            id: item,
            user_id: this.currentUser.user_id,
          };
          const result = await MotivesService.deleteMotives(params);
          if (result.status == 200) {
            this.showToast(
              "success",
              "top-right",
              "Congratulation!",
              "CheckIcon",
              "Motive has been deleted successfully"
            );
            this.$refs.refMotives.refresh();
          }
        } catch (error) {
          this.showToast(
            "danger",
            "top-right",
            "Error",
            "XIcon",
            "Something went wrong with tracking!"
          );
          throw error;
        }
      }
    },
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
  },
};
</script>
